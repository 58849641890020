:root {
    --cAccent: #3978EF;
    --cBlack: #121212;
    --cGray: #333;
    --cLightGray: rgba(0, 0, 0, 0.1);
    --cblue: #00369b
}

.accordion-item--opened {
    color: #00f !important
}

.accordion-list__item {
    border: 1px solid transparent;
    border-radius: 10px;
    box-shadow: 0 0 .1rem .1rem rgba(55, 120, 239, .06), 0 5px 10px -10px rgba(55, 120, 239, .2);
    margin: 10px
}

.accordion-list__item:hover {
    border: 1px solid grey !important
}

.accordion-list__item+.accordion-list__item {
    border-top: 1px solid var(--cLightGray)
}

.accordion-list__item:focus {
    border: 2px solid #000
}

.accordion-item--opened .accordion-item__icon {
    transform: rotate(180deg)
}

.accordion-item--opened .accordion-item__inner {
    max-height: 100rem;
    transition-timing-function: cubic-bezier(0.895, 0.03, 0.685, 0.22);
    transition-duration: .5s;
    transition-property: max-height
}

.accordion-item--opened .accordion-item__content {
    opacity: 1;
    transform: translateY(0);
    transition: opacity .4s ease-in-out 0.4s, transform .4s ease-in-out 0.4s
}

.accordion-item__line {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1.2rem;
    background-color: #fff;
    z-index: 2;
    position: relative
}

.accordion-item__title {
    font-size: 1rem;
    margin: 0;
    font-weight: 600;
    color: var(--cblue);
    text-align: left
}

.accordion-item__icon {
    width: 1.2rem;
    height: 1.2rem;
    transition: transform .3s ease-in-out;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGAAAABgCAQAAABIkb+zAAABGklEQVR4Ae3RAcZCQRiF4buDfwshBGi+2UQgcIGAVtpSIuS/KyilG+UTcbk6zIH3GQBm3mM6AAAAAAAAAACA+eqf/yZBXcV/2XeCVPYx1FXj/FjGUMd45AQp/1HHGGLZNL+e61jHnKDmv8652YT1IvPfE2LX/Sh27/ycsF60yT/lk58JYn6eU4MJccjnlAmZ/33i0OAH4jg9Qcw/5g9YJpS+m6n0xvzpCfVe+nn59S7kGyYo+YYJWz3fO+E2PaFs9XzPhMy/6fmWCXq+YUJs9HzrhLh+JsQmrnq+bYKeb52g53snXPR88wQ93z9Bz/dP0PP9E/R89wQ93zpBz7dO0POtE/R86wQ93zpBzzdP+MoHAAAAAAAAAADAExTnTW20AtjhAAAAAElFTkSuQmCC);
    opacity: .6
}

.accordion-item__inner {
    max-height: 0;
    overflow: hidden;
    text-transform: cubic-bezier(0.95, 0.05, 0.795, 0.035);
    transition-duration: .5s;
    transition-property: max-height;
    z-index: 1;
    position: relative
}

.accordion-item__content {
    opacity: 0;
    transform: translateY(-1rem);
    transition: opacity .1s linear 0.5s, transform .1s 0.5s;
    padding: 0 1.2rem 1.2rem
}

.accordion-item__paragraph {
    text-align: justify;
    margin: 0;
    font-size: 14px;
    color: var(--cGray);
    font-weight: 300;
    line-height: 1.3
}