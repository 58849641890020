.fm-bg,
.menuIcon,
.submenu li {
    cursor: pointer
}

li,
ul {
    list-style: none;
    text-decoration: none
}

.logo img {
    width: 170px
}

.header.scrolled {
    background: linear-gradient(0deg, #0054ba, #002655) !important;
}

.header {
    font-size: 14px;
    position: fixed;
    top: 0;
    height: 70px;
    padding: 0 40px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 10
}

li a {
    color: #fff !important
}

.links-header ul {
    padding: 0 !important;
    margin-left: 0 !important;
    display: flex
}

.links-header ul li {
    padding: 5px 2px;
    text-align: left;
    font-size: 11px;
    font-weight: 600
}

.nav-item {
    text-transform: uppercase
}

.active {
    border-radius: 16px;
    color: #333 !important;
    background-color: #fff
}

.navbar-nav {
    justify-content: center;
    align-items: center
}

.nav-link {
    padding: 4px 14px  2.6px 14px;
    align-items: center !important
}

.nav-link:hover,
.submenu li a:hover {
    color: #fecc81 !important
}

.fm-bg {
    margin: 0 20px;
    width: 45px;
    height: 45px;
    border-radius: 50%;
    padding: 8px 2px;
    transition: 1s;
    transform: scale(1.5)
}

.fm-bg img {
    margin: 7px 0;
    width: 2.34rem
}

.t-nav-color {
    height: 85px;
    padding: 0 70px;
    width: 100%;
    color: #fff
}

.submenu {
    visibility: hidden;
    display: none;
    font-size: 14px;
    position: absolute;
    top: 70%;
    padding: 7px 18px !important;
    border-radius: .4rem;
    background: linear-gradient(0deg, #0054ba, #002655) !important;
    z-index: 1;
    flex-direction: column;
    justify-content: space-evenly
}

.submenu li a {
    margin: 2px 3px 2px 2px;
    display: inline-block;
    padding: 4px 6px 3.2px 6px;
}

.submenu:hover {
    color: #333
}

.nav-item .submenu {
    padding: 10px 0 !important
}

.nav-item:active .submenu,
.nav-item:hover .submenu {
    visibility: visible
}

.submenu li {
    padding: 0 5px;
    list-style: none
}

.submenu li:hover {
    color: #792b2b
}

.res-nav {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    gap: 100px
}

.menuIcon,
.mobile-menu-button {
    display: none !important
}

@media (max-width:767px) {
    .header {
        padding: 10px !important
    }

    .logo img {
        width: 170px
    }

    .submenu li a {
        margin: 0 10px
    }
}

@media (max-width:1162px) {
    .submenu li a {
        padding: 6px 18px 6px 16px !important
    }

    .links-header ul li {
        width: 100%;
        margin: 0;
        border-bottom: 1px solid #fff
    }

    .nav {
        margin-top: 15px !important;
        width: 100%
    }

    .nav-link {
        border-radius: 0 !important
    }

    .flex-con {
        display: flex;
        flex-wrap: wrap
    }

    .submenu {
        position: absolute;
        top: auto;
        width: 100%;
        text-align: left;
        border-radius: 0;
        z-index: 2
    }

    .menuIcon,
    .mobile-menu-button {
        display: block !important;
        position: relative;
        z-index: 1;
        transition: transform .3s
    }

    .header {
        transition: transform .3s;
        flex-direction: row
    }

    .links-header ul {
        display: inline-block;
        width: 240px
    }

    .links-header {
        display: block !important;
        position: fixed;
        height: 100%;
        width: 240px;
        right: 0;
        box-shadow: 0 -2px 7px #3d3c3c;
        top: 0;
        transition: .8s;
        background: linear-gradient(101deg, #0054ba, #002655) !important
    }

    .header .links-header {
        width: 0
    }

    .header.mobile-menu-open .links-header {
        width: 240px
    }

    .nav {
        position: fixed;
        top: 55px
    }
}

.menuIcon {
    display: block;
    position: fixed;
    right: 15px;
    top: 30px;
    height: 23px;
    width: 27px;
    z-index: 12
}

.icon-bars,
.icon-bars::after,
.icon-bars::before {
    background: #fff;
    position: absolute;
    height: 2px
}

.icon-bars {
    left: 1px;
    top: 45%;
    width: 20px;
    -webkit-transition: .4s;
    transition: .4s
}

.icon-bars::before {
    content: '';
    left: 0;
    top: -8px;
    width: 20px;
    -webkit-transition: width .3s .4s;
    transition: width .3s .4s
}

.icon-bars::after {
    margin-top: 0;
    content: '';
    left: 0;
    bottom: -8px;
    width: 20px;
    -webkit-transition: width .3s .4s;
    transition: width .3s .4s
}

Bars Shadows .icon-bars.overlay {
    background: #b7c7d3;
    width: 20px;
    animation: 3s .5s infinite middleBar;
    -webkit-animation: 3s .5s infinite middleBar
}

@keyframes middleBar {

    0%,
    100% {
        width: 0
    }

    50% {
        width: 20px
    }
}

@-webkit-keyframes middleBar {

    0%,
    100% {
        width: 0
    }

    50% {
        width: 20px
    }
}

.icon-bars.overlay::before {
    background: #b7c7d3;
    width: 10px;
    animation: 3s .2s infinite topBar;
    -webkit-animation: 3s infinite topBar
}

@keyframes topBar {

    0%,
    100% {
        width: 0
    }

    50% {
        width: 10px
    }
}

@-webkit-keyframes topBar {

    0%,
    100% {
        width: 0
    }

    50% {
        width: 10px
    }
}

.icon-bars.overlay::after {
    background: #b7c7d3;
    width: 15px;
    animation: 3s 1s infinite bottomBar;
    -webkit-animation: 3s 1s infinite bottomBar
}

@keyframes bottomBar {

    0%,
    100% {
        width: 0
    }

    50% {
        width: 15px
    }
}

@-webkit-keyframes bottomBar {

    0%,
    100% {
        width: 0
    }

    50% {
        width: 15px
    }
}

.menuIcon.toggle .icon-bars {
    top: 5px;
    transform: translate3d(0, 5px, 0) rotate(135deg);
    transition-delay: 0.1s;
    transition: transform .4s cubic-bezier(.68, -.55, .265, 1.55)
}

.menuIcon.toggle .icon-bars::before {
    top: 0;
    transition-delay: 0.1s;
    opacity: 0
}

.menuIcon.toggle .icon-bars::after {
    top: 10px;
    transform: translate3d(0, -10px, 0) rotate(-270deg);
    transition-delay: 0.1s;
    transition: transform .4s cubic-bezier(.68, -.55, .265, 1.55)
}

.menuIcon.toggle .icon-bars.overlay {
    width: 20px;
    opacity: 0;
    -webkit-transition: none;
    transition: none
}