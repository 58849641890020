.footer {

    position: sticky;
    top: 100%  ;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0 0 0 18px;
    background: linear-gradient(331deg, #0054ba, #002655) !important
}

.footer .left-context {
    padding: 20px;
    width: 10%
}

.footer .left-context img {
    width: 220px
}

.foo-flex {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 0 20px
}

.foo-flex a {
    color: #fff !important;
    text-decoration: none;
    display: inline-block;
    padding: 0 10px
}

.footer02 p {
    font-size: 14px;
    text-align: center;
    color: #fff;
    margin-bottom: 0 !important
}

.center-context span {
    padding: 0 4px 6px 5px
}

.social-icons01 a i {
    padding: 0 5px
}

.social-icons01 {
    padding: 10px 0
}

.details {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
    font-size: 14px
}

.details a {
    color: #fff !important
}

.footer .right-context {
    padding: 20px 90px 20px 0;
    width: 10%
}

@media screen and (max-width:991px) {
    .footer {
        flex-direction: column
    }

    .footer .left-context {
        width: auto
    }

    .footer .right-context {
        padding: 20px 0;
        width: auto
    }
}

@media screen and (max-width:540px) {
    .infofooter {
        flex-direction: column
    }

    .foo-flex a {
        border: none !important
    }
}