@import url(https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,300;0,400;0,500;0,600;0,700;1,100;1,300;1,400;1,500;1,600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Dancing+Script&display=swap);

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    text-decoration: none;
    list-style: none;
    font-family: Poppins, sans-serif !important
}
body,html {
    overflow-x: hidden;
    /* height: 100vh; */

}




.t-mr {
    margin: 4rem
}

.drive-text h2 {
    text-align: center
}

.drive-text p {
    text-align: justify
}

.cont-para,
.first-para,
.fourth-para,
.second-para,
.third-para {
    padding: 2rem 0;
    width: 100%
}

.cont-drive {
    align-items: center;
    /* margin: 55px 0; */
    /* width: 600px; */
}

.cont-drive h3 {
    text-align: left;
    margin-bottom: 1rem;
    color: #0054ba;
    font-size: 24px
}

.cont-drive ul li {
    line-height: 21px !important;
    list-style: circle !important;
    font-size: 14px;
}

.recaptachdesign>div {
    margin: 10px auto;
    text-align: center;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center
}

.traffic-main {
    display: flex;
    flex-wrap: wrap;
    padding: 10px 0 0;
    justify-content: center;
    flex: 1
}

.mobApp-container {
    margin-top: 40px
}

.onlineappointbtn {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    padding: 0 2px;
    margin: 0 10px
}

.onlineappointbtn-link {
    padding: 4px;
    color: #103153 !important;
    font-weight: 800 !important;
    font-size: 11px !important;
    border-radius: 7px !important;
    background: #fefefe
}

.locationsection {
    padding: 8px
}

@media screen and (max-width:1162px) {
    .onlineappointbtn {
        width: 90%;
        margin: 15px auto
    }

    .onlineappointbtn-link {
        width: 100%;
        display: block;
        padding: 6px 4px;
        text-align: center
    }
}




.cont-all{
    width: 100%;
    /* height: 100dvh; */
    /* padding: 200px; */
}




/* 
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {


    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
} */

.sub{

    padding-bottom: 60px !important ;
}
.main-container{
    height: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    /* overflow-y: auto; */
}

.second-sectionImage{
    margin-right: 10px;
    padding-bottom: 15px;
}
.second-sectionImage img{
    width: 95px;
    box-shadow: 4px 6px 5px 1px rgba(0, 0, 0, .8);
    align-items: center;
    border-radius: 10px;
    height: 95px;
    object-fit: fill;
}
.licenceVer li{
    font-size: 16px;
    line-height: 28px;
    display: grid;
    grid-template-columns: 0.5fr 1fr;
    margin-bottom: 5px;
}
.licenceVer li span{
    
}
.licenceVer li p{
    margin: 0;
    font-size: inherit;
    font-weight: 500;
    color: rgb(23, 72, 132);
}
.verifyClose{
    position: absolute;
    right: -7px;
    top: -10px;
}
.licenceVerTop{
    flex: 1;
    padding-left: 12px;
    margin: 0;
    padding-bottom: 15px;
}
.licenceVerTop li{
    display: flex;
    font-size: 17px;
}
.licenceVerTop li h6{
    color: rgb(23, 72, 132);
    margin: 0;
    font-weight: 600;
    font-size: 17px;
}
.licenceVerTop li p{
    color: rgb(183, 53, 53);
    margin: 0;
    font-weight: 500;
    padding-left: 10px;
}
.licenceVerTop li span{
    color: black;
    padding-right: 10px;
    font-weight: 400;
}
.boxVerify{
    align-items: end;
    box-shadow: 0 2px 0 0 rgb(0 0 0 / 12%) !important;
    padding-bottom: 0px !important;
}