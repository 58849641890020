.subdriving {
    /* padding-bottom: 60px; */
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: flex-start;
}
.drivingLiencing
{
    padding: 15px 0;
}
.driveBtns {
    
    width: 300px;
}
.main-content-accord{
    margin-bottom: 20px;
}
.main-content-accord h6{
    color: #174782;
    margin: 14px 0 2px 0;
    padding-left: 15px ;
    text-align: left;
}
.content{
    flex: 1;
}
.driveBtns .buttonStyle {
    /* all: unset; */
    cursor: pointer;

    width: 180px;
    height: 40px;
    padding: 3px;
    border: none;
    color:#174782;
    background-color: #fff;
    /* border: 1px solid transparent ; */
    margin: 20px;
    font-size: 16px;
    border-radius: 6px;
    box-shadow: 0 1px 5px 0px  rgb(128, 128, 128); /* Adjust the values as needed */
}
.driveBtns .buttonStyle:hover{
    border: 1px solid #174782;
    box-shadow: 0 3px 6px 0 rgb(238, 238, 238);
}
.activebtns{
    font-weight: 500 !important;
    border:1px solid rgb(23, 71, 130) !important;
    color: #fff !important;
    background: rgb(23, 71, 130) !important;
    /* background: linear-gradient(47deg, rgb(23, 71, 130), rgba(23, 71, 130, 0.83)) !important; */
}
.acording-req span{
    font-size: 14px;
    padding: 0 13px;
}
.acording-req .span-icons{
    font-size: 18px !important;
    padding: 0 13px;
    color: #174782;
}


.acording-req{
    transition: all 0.5s;
    margin: 0 10px;
    border: 2px solid rgb(23, 71, 130);
    border-radius: 20px;
    width: 730px;
    display: flex;
    justify-content: space-between;
    /* background-color: skyblue; */
}


.main-accord h6{
    padding: 0px 10px;
    text-align: left;
}
.expandImg{
    overflow-y: scroll;
    width: 100%;
    height: 300px;
    background-color: lightgreen;
}
.process img{
    width: 25vw;
}


.acrodion-list-data{
    padding: 10px 0 0 0 ;
}