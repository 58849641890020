.btn {
    border: none !important
}

.btn button {
    padding: 12px;
    border-radius: 5px;
    width: 160px;
    border: none !important;
    background-color: #fff;
    box-shadow: 3px 4px 4px rgba(0, 0, 0, .54);
    text-decoration: none;
    font-size: 16px;
    font-weight: 600;
    color: #002655;
    cursor: pointer;
    position: relative;
    outline: 0;
    transition: background-color .5s ease-in-out, color .5s ease-in-out
}

.active button,
.btn button:focus,
.btn button:hover {

    background: linear-gradient(90deg, #0054ba, #002655);
    color: #fff
}

.btn button:focus {
    border: none !important
}