.main-section {
    width: 100%;
    padding: 10px 0 ;
    text-align: left;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.sec-img img {
    padding: 0 20px;
    width: 300px !important;
}

.sec-text h1 {
    font-size: 32px;
    font-weight: 800;
    letter-spacing: 2px;
    color: rgb(23, 72, 132);
    text-shadow: 0 4px 6px rgb(186, 187, 188);
}

.sec-text p {
    font-size: 14px;
    text-align: justify;
}
.second-section{
    width: 100%;
    height: 52vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: url('../assets/animat/station.png');
}
.second-section h1{
    color: #fffdfdfc;
    text-shadow: 0 4px 6px rgb(75 106 176);
}
.second-section p{
    color: rgb(242, 242, 242);
}
.second-text, .sec-text{
    width: 60%;
}
.secondImg img {
    /* padding: 0 20px; */
    width: 240px;
    height: 240px;
    border-radius: 100%;
    border: 5px solid rgb(23, 72, 132);
}

.th-sect{
    padding-left: 14px;
}
.section p{
    margin-bottom: 0 !important;
}