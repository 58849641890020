.heading,
.wrapper .title {
    font-weight: 600;
    text-align: center
}

.wrapper form .field,
.wrapper form .field1 {
    height: 42px;
    margin-top: 10px;
    position: relative;
    width: 100%
}

.wrapper {
    margin: 10px 2rem;
    width: 450px;
    background: #fff;
    border-radius: 15px;
    box-shadow: 0 15px 20px rgba(0, 0, 0, .1)
}

.heading {
    font-size: 18px;
    padding-top: 25px
}

.wrapper .title {
    font-size: 32px;
    line-height: 60px;
    color: #fff;
    user-select: none;
    border-radius: 15px 15px 0 0;
    background: linear-gradient(-135deg, #0054ba, #002655)
}

.wrapper form {
    padding: 10px 30px ;
}

.wrapper form .field1 {
    text-align: center
}

.wrapper form .field input {
    height: 100%;
    width: 100%;
    outline: 0;
    font-size: 17px;
    padding-left: 20px;
    border: 1px solid #d3d3d3;
    border-radius: 25px;
    transition: .3s
}

.wrapper form .field1 input {
    height: 100%;
    width: 50%;
    outline: 0;
    font-size: 17px;
    border: 1px solid #d3d3d3;
    border-radius: 25px;
    transition: .3s
}

.wrapper form .field label {
    position: absolute;
    top: 50%;
    left: 20px;
    color: #999;
    font-weight: 400;
    font-size: 17px;
    pointer-events: none;
    transform: translateY(-50%);
    transition: .3s
}

form .field input:focus~label,
form .field input:valid~label {
    top: 0;
    font-size: 16px;
    color: #4158d0;
    background: #fff;
    transform: translateY(-50%)
}

form .field1 input[type=submit] {
    display: inline-block;
    width: 120px;
    color: #fff;
    font-size: 18px;
    font-weight: 500;
    cursor: pointer;
    background: linear-gradient(135deg, #0054ba, #002655)
}

form .field1 input[type=submit]:hover {
    background: linear-gradient(0deg, #0054ba, #002655)
}

.error-message {
    border-radius: 4px;
    text-align: center;
    padding: 0 20px;
    color: red;
    font-size: 14px;
    margin-top: 5px
}

.ful-height {
    height: auto !important
}