.st-btn a {
     color: #0054ba;
     cursor: pointer;
     width: 80px;
     height: 80px;
     display: inline-block;
     justify-content: center;
     background-color: #fff;
     font-size: 1.6rem;
     font-weight: 900;
     box-shadow: 0 0 0.5rem 0 grey;
     padding: 22px 23px !important;
     border-radius: 1rem;
     margin-bottom: 13px;
     text-align: center !important;
     transition: background-color .5s ease-in-out;
}

.st-btn a:hover {
    color: #fff;
    background-color: #0353b5
}

.active-card a,
.st-btn a:focus {
    color: #fff;
    background-color: #0353b5 !important
}

.st-btn h3 {
    text-align: center !important;
    font-size: 13px
}

.d-station {
    display: flex;
    justify-content: space-between;
    align-items: center
}

.box1 {
    overflow: hidden;
    box-shadow: 0 0 0.3rem 0 grey;
    border-radius: 10px;
    width: 100%;
    height: 315px;
}

.st-img {
    display: block
}

.st-img img {
    width: 195px;
    position: relative;
    top: 7.9rem;
    right: 10.2rem;
}

.hidden {
    visibility: hidden
}

.pub-center {
    display: flex;
}

@media (max-width:1200px) {
    .st-img img {
        top: 15rem;
        right: 11.2rem;
        width: 200px
    }
}

@media (max-width:992px) {
    .pub-center {
        justify-content: center !important
    }
}

@media (max-width:768px) {
    .pub-center {
        display: flex;
        flex-direction: column !important
    }

    .st-start {
        justify-content: flex-start !important
    }

    .fl-st .row {
        flex-wrap: wrap;
        flex-direction: row
    }
}

@media (max-width:991px) {
    .st-img img {
        width: 250px;
        top: -6rem !important;
        left: 10rem
    }
}

@media (max-width:767px) {
    .box1 {
        margin: 0 auto;
        width: 80%
    }

    .col-lg-3 {
        width: 33.33%
    }
}

@media (max-width:594px) {
    .col-lg-3 {
        width: 100%
    }

    .st-img img {
        display: none
    }
}

@media (max-width:618px) {
    .fl-st .row {
        flex-wrap: wrap
    }
}