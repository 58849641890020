

.cont-item {
    border-radius: 8px;
    min-width: 742px !important;
    min-height: 55.5vh !important;
    display: flex;
}

.cont-item .contactInfo {
    border-radius: 6px;
    width: 225px;
    /* height: calc(100% - 80px); */
    background: linear-gradient(0deg, #0054ba, #002655) !important;
    z-index: 1;
    padding: 2px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    justify-content: space-between;
    box-shadow: 12px 10px 19px 0px rgba(0, 0, 0, .15);
}

.container .contactInfo h2 {
    color: #fff;
    font-size: 24px;
    font-weight: 700
}

.container .contactInfo ul.info {
    /* position: relative; */
}

.container .contactInfo ul.info li {
    position: relative;
    list-style: none;
    display: flex;
    margin: 26px 0;
    cursor: pointer;
    align-items: flex-start
}

.container .contactInfo ul.info li span:first-child {
    width: 30px;
    min-width: 30px
}

.container .contactInfo ul.info li span:first-child img {
    max-width: 110%
}

.container .contactInfo ul.info li span:nth-child(2) {
    color: #fff;
    margin-left: 5px;
    font-weight: 300;
    padding:5px 4px;
    font-size: 11px;
    text-align: left ;
}

.container .contactInfo ul.sci {
    position: relative;
    display: flex;

}

.container .contactInfo ul.sci li {
    list-style: none;
    margin-right: 15px;
    width: 2rem;
    height: 2rem;
    border-radius: 50% !important
}

.container .contactInfo ul.sci li a {
    font-size: 25px;
    text-decoration: none;
    color: #fff !important
}

.container .contactInfo ul.sci li a:hover {
    color: #e47a23 !important
}

.container .contactInfo ul.sci li a i {
    color: #fff !important
}

.container .contactForm {
    width: 500px;
    padding: 20px;
    max-width: 600px;
    background: #fff;
    box-shadow: 4px 2px 10px rgba(0, 0, 0, .25);
}

.container .contactForm h2 {
    color: #0f3959;
    font-size: 24px;
    font-weight: 500
}

.container .contactForm .formBox {
    position: relative;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    
}

.container .contactForm .formBox .inputBox {
    text-align: left;
    position: relative;
    font-size: 14px;
    margin-bottom: 15px
}

.container .contactForm .formBox .inputBox.w50 {
    width: 47%
}

.container .contactForm .formBox .inputBox.w100 {
    width: 100%
}

.container .contactForm .formBox .inputBox input,
.container .contactForm .formBox .inputBox textarea {
    width: 100%;
    resize: none;
    font-size: 12px;
    font-weight: 300;
    color: #6c6565;
    border: none;
    outline: 0;
    border-bottom: 1px solid #777
}

.btn input[type=submit] {
    font-size: 14px;
    position: relative;
    border-radius: 50px;
    border: none;
    cursor: pointer;
    width: 82px;
    background: linear-gradient(180deg, #0054ba, #00306a, #0054ba) !important;
    color: #fff;
    max-width: 200px;
    padding: 5px 2px;
    transition: .3s;
}

.btn input[type=submit]:hover {
    transform: scale(1.05);
    background: linear-gradient(180deg, #0054ba, #0a62cc) !important
}

@media (max-width:1180px) {
  .cont-item .contactInfo{
    width: 500px !important;

  }
  .container .contactInfo ul.info li{
    margin: 6px 0 ;
  }
  .contactInfo{
    display: flex;
    flex-direction: row !important;
  }
  .sci{
    align-items: center;
    flex-direction: column !important;
  }
}

@media (max-width:768px) {
    .inputBox span {
        font-size: 14px
    }
    .container .contactInfo ul.info li{
        flex-direction: row !important;
    }
    .cont-item .contactInfo {
        max-width: 400px !important;
        /* display: none; */
        padding: 10px;
        /* height: auto */
    }

    .container .contactForm{
        max-width: 400px !important;

    }
}