.box-se,
.li-btn {
    display: flex
}

.verify-container {
    margin: 2rem
}

.cl-page {
    padding: 20px 0;
    line-height: 2rem;
    margin: 2rem auto;
    width: 70%;
    background-color: #fff;
    box-shadow: 0 0 .8rem 0 #c3c2c2;
    border-radius: 12px
}

.verify-logo img {
    height: 110px;
    width: 110px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center
}

.first-section {
    font-size: 18px;
    margin-top: 4%;
    text-align: left
}

.box-se,
.third-section {
    margin: 0 auto;
    width: 80%
}

.box-se {
    box-shadow: 0 2px 0 0 rgba(0, 0, 0, .6);
    padding-bottom: 15px;
    justify-content: space-between
}

.box-se span {
    color: #0054ba
}

.third-section {
    font-size: 18px;
    padding-bottom: 5px
}

.third-section ul {
    padding-top: 1.4rem
}

.second-section img {
    width: 60%;
    box-shadow: 4px 6px 5px 1px rgba(0, 0, 0, .8);
    align-items: center;
    border-radius: 10px
}

.li-btn button {
    color: #b73535;
    background: 0 0;
    border: none;
    font-size: 1.5rem;
    margin: 0 20px 50px 0
}