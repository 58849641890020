.mob-flip .secImg,
.rect-box2 .secImg {
    animation: none;
    z-index: 2;
    position: absolute
}

.mobApp {
    width: 95%;
    text-align: center;
    margin: 0 auto;
    border-radius: 1rem;
    padding-top: 30px
}

.appText {
    width: 100%
}

.appImg img {
    width: 75%
}

.appImg video {
    width: 80%
}

.appText h1 {
    text-align: center;
    font-size: 2.7rem;
    text-transform: capitalize;
    font-weight: 700;
    color: #0054ba
}

.mob-flip img {
    width: 10%;
    top: -4px;
    position: absolute;
    display: flex;
    justify-content: flex-start;
    left: -70px
}

.mob-flip .secImg {
    width: 5%;
    top: 32px;
    left: -30px
}

.rect-box2 .secImg {
    width: 7%;
    top: -62px;
    right: -55px
}

.btn-slide,
.btn-slide2 {
    position: relative;
    display: inline-block;
    height: 50px;
    width: 200px;
    line-height: 50px;
    padding: 0;
    border-radius: 50px;
    background: #fdfdfd;
    border: 2px solid #174884;
    margin: -20px 10px;
    transition: .5s
}

.btn-slide2 {
    border: 2px solid #174884
}

.btn-slide2:hover,
.btn-slide:hover {
    border: none;
    background: linear-gradient(57deg, #0054ba, #002655) !important
}

.btn-slide2:hover span.circle2,
.btn-slide:hover span.circle {
    margin: 5px ;
    left: 100%;
    margin-left: -45px;
    background-color: #fdfdfd;
    color: #0054ba
}

.btn-slide2:hover span.circle2 {
    color: #174884
}

.btn-slide2:hover span.title2,
.btn-slide:hover span.title {
    left: 40px;
    opacity: 0
}

.btn-slide2:hover span.title-hover2,
.btn-slide:hover span.title-hover {
    opacity: 1;
    left: 40px
}

.btn-slide span.circle,
.btn-slide2 span.circle2 {
    display: block;
    background-color: #174884;
    color: #fff;
    position: absolute;
    float: left;
    margin: 3px;
    line-height: 40px;
    height: 40px;
    width: 40px;
    top: 0;
    left: 2px;
    transition: .5s;
    border-radius: 50%
}

.btn-slide2 span.circle2 {
    background-color: #174884
}

.btn-slide span.title,
.btn-slide span.title-hover,
.btn-slide2 span.title-hover2,
.btn-slide2 span.title2 {
    position: absolute;
    left: 75px;
    text-align: center;
    margin: 0 auto;
    font-size: 16px;
    font-weight: 700;
    color: #174884;
    transition: .5s
}

.btn-slide2 span.title-hover2,
.btn-slide2 span.title2 {
    color: #174884;
    left: 80px
}

.btn-slide span.title-hover,
.btn-slide2 span.title-hover2 {
    left: 80px;
    opacity: 0;
    color: #fff
}

.mob-img-1 {
    margin: 0 auto;
    width: 33% !important;
    padding: 20px 0;
    display: none
}

@media (max-width:768px) {
    .rect-box3 img {
        display: none
    }

    .mob-img-1 {
        display: block
    }

    .mob-img-2 {
        display: none !important
    }
}

.appImg .sticks {
    width: 10%;
    position: absolute;
    bottom: 120px;
    left: 120px;
    z-index: -2
}