.link a,
a {
    text-decoration: none
}

.hero-card {
    margin: 30px 10px;
    width: 200px;
    background: #fff;
    border: 1px solid rgba(0, 0, 0, .15);
    border-radius: 16px;
    box-shadow: 3px 4px 6px 0 rgba(0, 0, 0, .15);
    text-align: center;
    position: relative
}

.vector-top img {
    height: 100%;
    position: absolute;
    top: 0;
    left: -67px
}

.image img {
    width: 64%;
    padding-top: 15px
}

.heading h2 {
    color: #1a4c7f;
    font-size: 14px;
    font-weight: 700;
    position: relative
}

.paragraph p {
    padding: 5px 2px;
    color: #fff;
    font-size: 13px
}

.on-hover {
    width: 100%;
    height: 0;
    border-radius: 14px;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #1e368CBD;
    overflow: hidden;
    transition: .5s
}

.heading-on-hover h2 {
    color: #fff;
    font-size: 14px;
    font-weight: 700;
    padding: 11px 6px
}

.link a {
    background: 0 0;
    border-radius: 30px;
    color: #fff;
    font-weight: 500;
    display: inline-block;
    padding: 6px 10px;
    font-size: 13px;
    border: 1px solid #fff
}

.link a:hover {
    background: #fff;
    color: #09366fd4
}

.hero-card:hover .on-hover {
    height: 100%;
    background: linear-gradient(360deg, #0054ba, rgba(0, 84, 186, .9))
}