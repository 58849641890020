h3 {
    font-weight: 600;
    font-size: 14px
}

.card1 p {
    font-size: 17px;
    font-weight: 400;
    line-height: 20px;
    color: #666
}

p.small {
    padding: 19px 0;
    text-align: left;
    font-size: 13px
}

.go-corner {
    display: flex;
    align-items: center;
    justify-content: center !important;
    position: absolute;
    width: 32px;
    height: 32px;
    overflow: hidden;
    top: 0;
    right: 0;
    background-color: #0054ba;
    border-radius: 0 4px 0 32px
}

.go-arrow {
    margin-top: -4px;
    margin-right: -4px;
    color: #fff;
    font-family: courier, sans
}

.card1 {
    display: block;
    position: relative;
    width: 310px;
    box-shadow: 0 0 10px rgba(0, 0, 0, .25);
    background-color: #fff;
    border-radius: 4px;
    padding: 0 20px;
    text-decoration: none;
    z-index: 0;
    overflow: hidden;
    height: 20rem
}

.card1:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: -16px;
    right: -28px;
    background: linear-gradient(0deg, #0054ba, #002655) !important;
    height: 35px;
    width: 60px;
    border-radius: 32px;
    transform: scale(1);
    transform-origin: 50% 50%;
    transition: transform .7s ease-out
}

.card1:hover:before {
    transform: scale(29)
}

.card1:hover p {
    transition: .3s ease-out;
    color: rgba(255, 255, 255, .8)
}

.card1:hover h3 {
    transition: .3s ease-out;
    color: #fff
}

.ser-head {
    text-align: center;
    justify-content: space-between;
    align-items: flex-end
}

.ser-head h3 {
    color: #0051b8d4;
    font-size: 22px
}

.ser-head img {
    width: 7rem;
    height: 7rem
}

.ser-img {
    padding: 0 4.5rem;
    margin: 0 auto
}

.ser-card {
    width: 85%;
    margin: 0 auto
}

.ser-heading {
    padding: 0 0 20px
}

.ser-heading h1 {
    font-size: 26px;
    text-align: center;
    letter-spacing: 2px;
    color: #fff;
    font-weight: 650;
    position: relative
}

.ser-heading h1::before {
    content: '';
    position: absolute;
    left: 6px;
    bottom: -2px;
    width: 162px;
    height: 2px;
    background-color: #fff
}

.service-img {
    background: url('../components/assets/animat/station.png') center center/cover;
    width: 100%;
    color: #fff;
    padding: 20px 0;
    transition: .8s
}

.service{
    padding: 30px 0 ;
}